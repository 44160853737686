<template>
  <el-dialog :title="type === 'add' ? '添加组件' : '编辑组件'" v-model="visible" width="560px" @close="handleClose"
    custom-class="dialog" :close-on-click-modal="false">
    <el-form :model="ruleForm" :rules="rules" ref="formRef" label-width="100px" class="good-form" v-loading="loading">
      <el-form-item label="组件品牌" prop="brandName">
        <el-select v-model="ruleForm.brandName" placeholder="请选择组件品牌">
          <el-option v-for="option in BRAND_NAME_TYPES" :key="option.value" :label="option.label"
            :value="option.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="组件型号" prop="comModel">
        <el-select v-model="ruleForm.comModel" placeholder="请选择组件型号">
          <el-option v-for="option in COM_MODAL_TYPE" :key="option.value" :label="option.label" :value="option.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="组件功率" prop="comPower">
        <el-input type="number" v-model="ruleForm.comPower" placeholder="请输入组件功率" />
      </el-form-item>
      <el-form-item label="组件长度" prop="comLong">
        <el-input type="number" v-model="ruleForm.comLong" placeholder="请输入组件长度" />
      </el-form-item>
      <el-form-item label="组件宽度" prop="comWidth">
        <el-input type="number" v-model="ruleForm.comWidth" placeholder="请输入宽度" />
      </el-form-item>
      <el-form-item label="组件高度" prop="comHigh">
        <el-input type="number" v-model="ruleForm.comHigh" placeholder="请输入组件高度','隔开" />
      </el-form-item>
      <el-form-item label="固定点间距" prop="spacing">
        <el-input type="number" v-model="ruleForm.spacing" placeholder="请输入固定点间距" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="submitForm" :loading="loading">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { reactive, ref, toRefs, computed } from 'vue';
import { useStore } from 'vuex';
import { BRAND_NAME_TYPES, COM_MODAL_TYPE } from '../const';
export default {
  name: 'DialogAddHotGood',
  props: {
    type: String
  },
  setup() {
    const store = useStore();
    const formRef = ref(null);
    const initialValue = {
      brandName: '',
      comModel: '',
      comPower: '',
      comLong: '',
      comWidth: '',
      comHigh: '',
      spacing: ''
    }
    const state = reactive({
      visible: false,
      id: '',
      ruleForm: { ...initialValue },
      rules: { }
    })
    // 开启弹窗
    const open = (data) => {
      state.visible = true
      if (data && data?.id) {
        state.id = data?.id;
        console.log(data);
        state.ruleForm = {
          ...data
        }
      } else {
        state.ruleForm = { ...initialValue }
      }
    }

    // 关闭弹窗
    const close = () => {
      state.visible = false
    }
    const handleClose = () => {
      formRef.value.resetFields()
    }

    const submitForm = () => {
      formRef.value.validate((valid) => {
        if (valid) {
          store.dispatch('module/addOrUpdate', {
            ...state.ruleForm,
            id: state.id
          }).then(res => {
            if (res.code === 200) {
              handleClose();
              state.visible = false
            }
          });
        } else {
          return false
        }
      })
    }

    const handleAtlasSuccess = (response) => {
      state.ruleForm.atlas = response.data;
    }

    const handleBomSuccess = (response) => {
      state.ruleForm.bom = response.data;
    }

    return {
      ...toRefs(state),
      open,
      close,
      formRef,
      submitForm,
      handleClose,
      COM_MODAL_TYPE,
      handleBomSuccess,
      BRAND_NAME_TYPES,
      handleAtlasSuccess,
      loading: computed(() => store?.state?.module?.loading),
    }
  }
}
</script>

<style lang="scss" scoped></style>
