<template>
  <div style="height: calc(100% - 94px)">
    <el-table v-loading="loading" :data="dataSource" style="width: 100%" @selection-change="handleSelectionChange"
      table-layout="auto" height="100%">
      <el-table-column type="selection" width="55" />
      <el-table-column property="number" label="编号" />
      <el-table-column label="组件品牌" property="brandName" />
      <el-table-column label="组件型号" property="comModel" />
      <el-table-column label="组件功率" property="comPower" />
      <el-table-column label="组件长度" property="comLong" />
      <el-table-column label="组件宽度" property="comWidth" />
      <el-table-column label="组件高度" property="comHigh" />
      <el-table-column label="操作" width="140px" fixed="right">
        <template #default="scope">
          <el-button size="small" type="primary" @click="handleEdit(scope.row)">编辑</el-button>
          <el-button size="small" type="danger" @click="handleDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Dialog ref="dialogRef" type="edit" />
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { ElTable } from 'element-plus';
import { useStore } from 'vuex';
import Dialog from './dialog.vue';

const dialogRef = ref(null);

const store = useStore();
const multipleSelection = ref([]);

const handleSelectionChange = (val) => {
  multipleSelection.value = val
}

const dataSource = computed(() => {
  return store?.state?.module?.dataSource;
});

const loading = computed(() => {
  return store?.state?.module?.loading;
});

// 编辑
const handleEdit = (data) => {
  dialogRef.value.open(data);
}
// 删除
const handleDelete = (data) => {
  store.dispatch('module/delete', data.id);
}
</script>

<style lang="scss" scoped>
</style>
