<template>
  <div class="pagination">
    <el-pagination :current-page="page" :page-size="limit" small="small" layout="total, prev, pager, next"
      :total="total" background @current-change="handlePageChange" />
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  setup() {
    const store = useStore();

    const handlePageChange = (page) => {
      store.dispatch('module/queryData', {
        page: {
          page
        }
      });
    };

    return {
      handlePageChange,
      total: computed(() => store?.state?.module?.total),
      page: computed(() => store.state.module.page.page),
      limit: computed(() => store?.state?.module?.page?.limit)
    };
  }
}
</script>

<style lang="scss" scoped>
  .pagination {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 16px;
  }
</style>
