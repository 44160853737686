const arrToObj = data => {
  return Object.fromEntries(data.map(item => [item.value, item.label]));
};
/**
 * 组件品牌 brandName
 */
export const BRAND_NAME_TYPES = [
  {
    label: '晶科',
    value: '晶科'
  },
  {
    label: '晶澳',
    value: '晶澳'
  },
  {
    label: '隆基',
    value: '隆基'
  },
  {
    label: '海泰',
    value: '海泰'
  },
  {
    label: '爱旭',
    value: '爱旭'
  },
  {
    label: '环晟',
    value: '环晟'
  }
];
export const BRAND_NAME_TYPES_TEXT = arrToObj(BRAND_NAME_TYPES);

/**
 * 组件型号 comModel
 */
export const COM_MODAL_TYPE = [
  {
    label: '540',
    value: '540'
  },
  {
    label: '545',
    value: '545'
  },
  {
    label: '550',
    value: '550'
  },
  {
    label: '560',
    value: '560'
  },
  {
    label: '600',
    value: '600'
  }
];
export const COM_MODAL_TYPE_TEXT = arrToObj(COM_MODAL_TYPE);
